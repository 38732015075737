<template>
  <div>
    <div class="cancel-page">
      <img src="@/assets/images/svg/money.png" alt="" />
      <h1>{{ $t("Payment Canceled") }}</h1>
      <p>
        Your payment was not completed. If you have any questions, please
        contact support.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.cancel-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  padding: 100px 0;
  img {
    width: 300px;
  }
}

.btn {
  margin: 10px;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff; /* Bootstrap primary color */
}

.btn-secondary {
  background-color: #6c757d; /* Bootstrap secondary color */
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
